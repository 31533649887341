import { useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faTrash } from '@fortawesome/free-solid-svg-icons';

import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import UpgradePanel from '@/components/shared/upgrade-panel';
import PageControl from '@/components/shared/page-control';

import articleListStyles from '@/components/articles/article-list.module.scss';
import { opinionsApi } from '@/api';
import WidgetLink from '@/widgets/widget-router/widget-link';
import DeleteBookmarkModal from './delete-modal';
import useSubscription from '@/hooks/use-subscription';

import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { messageService } from '@/services';
import AgedDateFormat from '@/components/shared/aged-date-format';
import styles from './bookmark-list.module.css';
import ArticleModal from '@/components/articles/article-modal';

export default function BookmarkList() {
  const [articles, setArticles] = useState<Article[]>();
  const [offset, setOffset] = useState<number>(0);
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [showDeleteBookmarkModal, setShowDeleteBookmarkModal] = useState<string | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<string>();

  const fetchData = (offset: number) => {
    opinionsApi.getArticlesByOpinion('BOOKMARK', { page: offset }).then(
      value => {
        setArticles(value.content);
        setForbidden(false);
      },
      reason => {
        setForbidden(true);
      },
    );
  };

  const deleteBookmark = async (uri: string) => {
    await opinionsApi.removeOpinion(uri, 'BOOKMARK');
    const opinions = await opinionsApi.getOpinionsOfArticles(uri);

    if (!opinions[uri]) opinions[uri] = [];

    messageService.publish(MessageServiceConstants.BOOKMARKS_CHANGED, opinions);
    fetchData(offset);
  };

  const setPage = (offset: number) => {
    setArticles(undefined);
    setOffset(offset);
  };

  useEffect(() => {
    fetchData(offset);
  }, [offset]);

  useSubscription(MessageServiceConstants.BOOKMARKS_CHANGED, message => {
    fetchData(offset);
  });

  useTimer(
    30000,
    () => {
      fetchData(offset);
    },
    false,
  );

  if (forbidden)
    return (
      <UpgradePanel
        heading={'Contact us to read more'}
        leadMessage={'I would like to try: Bookmarks'}
      />
    );

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  return (
    <div className={'h-100 d-flex flex-column container-element'}>
      {/*<PageControl
        offset={offset}
        onChange={setPage}
      ></PageControl>
      */}
      {!articles && (
        <div style={{ textAlign: 'center' }} className={'mt-5'}>
          <GridLoader color={theme.info} />
        </div>
      )}

      {articles && articles.length == 0 && (
        <div className={' text-center mt-5 small'}>
          <span>No bookmarks.</span>
        </div>
      )}

      <div className={'flex-fill mt-3 overflow-auto container-responsive-text'}>
        <table className={'table overflow-auto table-sm table-hover'}>
          <tbody>
            {!!articles?.length ? (
              articles?.map(value => (
                <tr className={'align-middle'} key={'bm-' + value.uri}>
                  <td>
                    <div>
                      <strong
                        className={styles.bookmarkLink}
                        onClick={event => setSelectedArticle(value.uri)}
                      >
                        {value.headline}
                      </strong>
                    </div>
                    <div className={'d-flex justify-content-between align-items-center text-muted'}>
                      <small>
                        <AgedDateFormat format={'MMM D, h:mm a'} date={value.versioncreated} />
                      </small>

                      <span>
                        <button
                          style={{ fontFamily: 'Inter' }}
                          className={'btn btn-link link-danger   btn-sm'}
                          onClick={() => setShowDeleteBookmarkModal(value.uri)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
      <DeleteBookmarkModal
        showDeleteBookmarkModal={!!showDeleteBookmarkModal}
        setShowDeleteBookmarkModal={setShowDeleteBookmarkModal}
        onConfirm={() => deleteBookmark(showDeleteBookmarkModal || '')}
      />
      <ArticleModal articleId={selectedArticle} onClose={() => setSelectedArticle(undefined)} />
    </div>
  );
}
