import useWidgetLoaderData from '@/widgets/widget-router/use-widget-loader-data';
import PinnedArticle from '@/components/pins/pinned-article';
import { Article } from '@/model/article';
import { useContext, useEffect, useState } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import Loading from '@/components/shared/loading';
import useSubscription from '@/hooks/use-subscription';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { qCode } from '@/model/concept-item';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import { PinnedWidgetState } from '@/widgets/widgets/pinned/pinned-series-widget';

export default function PinnedArticlePage({ className }: { className?: string }) {
  const { article, title, genre, loaded } = useWidgetLoaderData() as {
    article: Article;
    title: string;
    genre: string;
    loaded: boolean;
  };

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  const { sendNotification } = useWidgetNotifications();

  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);

  useSubscription<Article>(MessageServiceConstants.NEWS_ARTICLE_TOPIC, message => {
    if (genre) {
      if (
        message.genre.some(value => qCode(value) == genre) ||
        message.subject.some(value => qCode(value) == genre)
      ) {
        setNewVersionAvailable(true);

        sendNotification({
          correlationId: `pinned-${article.uri}`,
          title: 'Pinned Series Update: ' + title,
          description: article.headline,
          durationMillis: 300_000,
          date: new Date(),
        });
      }
    }
  });

  useEffect(() => {
    widgetContainer.updateState<PinnedWidgetState>({ title });
  }, [title]);

  if (!loaded)
    return (
      <>
        <div className={''}>
          <Loading type={'grid'} />
        </div>
      </>
    );

  if (!article)
    return (
      <div className={'d-flex flex-column text-center justify-content-around h-100'}>
        <div>
          <h3>Upgrade to access this item</h3>
          <button className={'btn btn-warning'}>Contact us</button>
        </div>
      </div>
    );

  return (
    <PinnedArticle
      article={article as Article}
      className={className}
      newVersionAvailable={newVersionAvailable}
    />
  );
}
