import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import DataCalendarPage from '@/pages/calendar/data-calendar-page';
import EventPage from '@/pages/calendar/event-page';
import AuctionEventPage from '@/pages/calendar/auction-event-page';
import { selectApi } from '@/api';

export class DataCalendarWidget implements NewsWidget {
  defaultTitle: string = 'HSC';

  readonly id = '/calendar';

  initialize(host: WidgetHost) {}

  async hasPermission(permissions: string[]) {
    return permissions.some(value => /read:select:.*/.test(value));
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <div><FontAwesomeIcon icon={faCalendar} /> HSC</div>
      </>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} />
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/:view/:date',
        element: <DataCalendarPage />,
      },
      {
        path: '/event/:id',
        loader: async ({ params }) => {
          try {
            return await selectApi.getEvent(params.id || '');
          } catch (e) {
            return e;
          }
        },
        element: <EventPage />,
      },
      {
        path: '/auction-event/:date/:auctionHolderId',
        loader: async ({ params }) => {
          try {
            const start = parseInt(params.date ?? '-1') - 30_000;
            const end = parseInt(params.date ?? '-1') + 30_000;

            return (await selectApi.getCalendar(start, end)).filter(
              value =>
                value.auction &&
                value.auction.auctionProducts.length > 0 &&
                `${value.auction.auctionProducts[0].auctionHolder.id}` === params.auctionHolderId,
            );
          } catch (e) {
            return e;
          }
        },
        element: <AuctionEventPage />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/daily/today`;
  }
}
