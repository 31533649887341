import { Article } from '@/model/article';

import dayjs from 'dayjs';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import './pinned-article.css';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import DateFormat from '@/components/shared/date-format';

export default function PinnedArticle({
  article,
  className = 'note-yellow',
  newVersionAvailable,
}: {
  article: Article;
  className?: string;
  newVersionAvailable?: boolean;
}) {
  const navigate = useWidgetNavigate();

  return (
    <div
      className={
        'h-100 text-black p-1  h-100 overflow-auto d-flex flex-column container-element ' + className
      }
      style={{}}
    >
      <div className={'mb-2'}>
        <div className={'flex-grow-1'}>
          <h3>{article.headline}</h3>
        </div>

        <div className={'d-flex justify-content-between'}>
          <div className={'small '}>
            <div>
              <DateFormat format={'MMM D, h:mm a'} date={article.versioncreated} />,{' '}
              <strong>{dayjs(article.versioncreated).fromNow()}</strong>
            </div>
          </div>
          {article.byline && (
            <div className={' fst-italic  small text-end'}>By {article.byline}</div>
          )}
        </div>
      </div>

      <div className={'h-100 overflow-auto container-responsive-text '}>
        <ArticleBodyDisplay xhtml={article.body_xhtml} />
      </div>

      {newVersionAvailable && (
        <div className={'position-absolute bottom-0 end-0 p-2'}>
          <button className={'btn btn-sm btn-primary'} onClick={event => navigate('/')}>
            <FontAwesomeIcon icon={faRotateRight} /> New Version Available
          </button>
        </div>
      )}
    </div>
  );
}
