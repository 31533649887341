import { BarLoader, GridLoader } from 'react-spinners';
import theme from '@/index.module.scss';
import React from 'react';

export default function Loading({ type = 'grid' }: { type?: 'grid' | 'bar' }) {
  switch (type) {
    case 'bar':
      return (
        <div style={{ textAlign: 'center' }} className={'mt-5'}>
          <BarLoader color={theme.info} />
        </div>
      );
    default:
    case 'grid':
      return (
        <div style={{ textAlign: 'center' }} className={'mt-5'}>
          <GridLoader color={theme.info} />
        </div>
      );
  }
}
