import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faShareNodes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import { Button } from 'react-bootstrap';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import HasWidgetPermissions from '@/widgets/widget-permissions/has-widget-permissions';
import { WidgetUpgradeBadge } from '@/widgets/widget-permissions/widget-upgrade-badge';
import styles from './dashboard-widget-panel.module.css';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import useWidgetState from '@/hooks/use-widget-state';
import ClipboardButton from '../shared/clipboard-button';
import { openPopup } from '@/util/pop-up-util';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';

export default function DashboardWidgetPanel<T>({
  widget,
  editMode,
  initialState,
  onClose,
  onHeaderDoubleClick,
  onSave,
  highlight,
}: {
  widget: NewsWidget;
  editMode: Boolean;
  initialState?: T;
  onClose: (save?: boolean) => void;
  onHeaderDoubleClick: () => void;
  onSave: () => void;
  highlight: boolean;
}) {
  const [widgetPath, setWidgetPath] = useState<string>();

  const [hovering, setHovering] = useState<boolean>(false);
  const {
    state,
    preferences,
    setWidgetPreferences,
    updateWidgetPreferences,
    setWidgetState,
    updateWidgetState,
  } = useWidgetState<any, any>(widget.id, initialState, {});
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const myRef = useRef<HTMLDivElement | null>(null);

  const { notifications, clearNotificationsByWidgetId } = useWidgetNotifications();

  useEffect(() => {
    if (highlight) {
      myRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [highlight]);

  useEffect(() => {
    setNotificationCount(notifications.filter(value => value.widgetIds.includes(widget.id)).length);
  }, [notifications]);

  return (
    <div
      className={` h-100 `}
      style={{ userSelect: 'none' }}
      ref={myRef}
      onMouseEnter={event => setHovering(true)}
      onMouseOver={event => setHovering(true)}
      onMouseLeave={event => setHovering(false)}
    >
      <WidgetContainerContext.Provider
        value={{
          getPreferences(): any | undefined {
            return preferences;
          },
          setPreferences<T>(state: T): void {
            setWidgetPreferences(state);
          },
          updatePreferences<T>(state: Partial<T>): void {
            updateWidgetPreferences(state);
          },
          updateState<T>(state: Partial<T>): void {
            updateWidgetState(state);
          },
          setState: (updatedState: any) => {
            setWidgetState(updatedState);
          },
          getState() {
            return state as any;
          },
          get widgetId(): string | undefined {
            return widget.id;
          },
          close() {
            onClose(true);
          },
        }}
      >
        <div
          className={`${widget.containerClass ? widget.containerClass(state as any, preferences as any) : 'card rounded-2 h-100 border-0'} ${styles.widgetPanel}`}

          onMouseOver={event => {
            clearNotificationsByWidgetId(widget.id)
          }}
        >
          <div
            className={`card-body h-100 d-flex flex-column   ${(highlight && styles.flash) || ''}`}
            style={{}}
          >
            <div className={`d-flex justify-content-between align-items-center ps-2 ${styles.widgetPanelHeader}`}>
              <strong
                onDoubleClick={onHeaderDoubleClick}
                style={{ fontFamily: 'Inter', fontWeight: 700 }}
                className={
                  'drag-handle flex-grow-1 d-flex align-items-center gap-1 h-100  text-truncate '
                }
              >
                <h4 className={'mb-0 '}>{widget.headerPanel}</h4>
                <sup>
                  <WidgetUpgradeBadge widget={widget} />
                </sup>
                {notificationCount > 0 && (
                  <small>
                    <span className={'badge bg-danger'}>{notificationCount}</span>
                  </small>
                )}
              </strong>
              <span
                onDrag={event => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                className={'flex-shrink-0'}
              >
                {!editMode && (
                  <widget.headerActions>
                    <>
                      <ClipboardButton text={window.location.href + `?widget=${widget.id}`}>
                        <button className={'btn btn-link p-2'} onClick={event => {}}>
                          <FontAwesomeIcon icon={faShareNodes} />
                        </button>
                      </ClipboardButton>

                      <button
                        className={'btn btn-link p-2'}
                        onClick={() => openPopup(convertWidgetToReactPath(widget.id, widgetPath))}
                      >
                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                      </button>
                    </>
                  </widget.headerActions>
                )}
                {editMode && (
                  <button className={'btn btn-link link-danger'} onClick={() => onClose()}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                )}
              </span>
            </div>
            <div className={'overflow-hidden flex-shrink-1 h-100 position-relative '}>
              {editMode && (
                <div
                  className={
                    'position-absolute z-3 w-100 h-100 bg-body-secondary bg-opacity-75 d-flex text-center flex-column'
                  }
                >
                  <div className={'mt-auto mb-auto opacity-100 text-white'}>
                    <div className={'m-2 small'}>
                      Editing Dashboard. Grab the title bar above to move, and use handles to
                      resize. Save to continue.
                    </div>
                    <div>
                      {hovering && (
                        <Button
                          size={'sm'}
                          className={'opacity-100'}
                          onClick={onSave}
                          variant={'primary'}
                        >
                          Save Dashboard
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <HasWidgetPermissions widget={widget}>
                <LocalWidgetRouter widget={widget} onNavigate={setWidgetPath} />
              </HasWidgetPermissions>
            </div>
          </div>
        </div>
      </WidgetContainerContext.Provider>
    </div>
  );
}
