import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import DateFormat from '@/components/shared/date-format';
import dayjs from 'dayjs';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faRotateRight,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Article } from '@/model/article';
import { useEffect, useState } from 'react';
import Loading from '@/components/shared/loading';
import { articleApi } from '@/api';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import { openPopup } from '@/util/pop-up-util';

function ArticleDisplay({ article }: { article: Article }) {
  return (
    <div className={'h-100   h-100 overflow-auto d-flex flex-column container-element '} style={{}}>
      <div className={''}>
        <div className={'d-flex justify-content-between'}>
          <div className={'small '}>
            <div>
              <DateFormat format={'MMM D, h:mm a'} date={article.versioncreated} />,{' '}
              <strong>{dayjs(article.versioncreated).fromNow()}</strong>
            </div>
          </div>
          <div>
            {article.byline && <div className={'small fst-italic'}>By {article.byline}</div>}
          </div>
        </div>
      </div>

      <div className={'h-100 overflow-auto container-responsive-text mt-3 '}>
        <ArticleBodyDisplay xhtml={article.body_xhtml} />
      </div>
    </div>
  );
}

export default function ArticleModal({
  articleId,
  onClose,
}: {
  articleId?: string;
  onClose: () => void;
}) {
  const [article, setArticle] = useState<Article>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (articleId) {
      articleApi
        .getArticle(articleId)
        .then(setArticle)
        .finally(() => setLoading(false));
    } else {
      setArticle(undefined);
      setLoading(false);
    }
  }, [articleId]);

  const popOut = () => {
    openPopup(`/article/${article?.uri}`);
    onClose();
  };

  return (
    <Modal show={articleId != null} onHide={onClose} size={'lg'}>
      <ModalHeader
        className={'align-items-baseline sticky-top'}
        style={{ backgroundColor: 'var(--bs-body-bg' }}
      >
        <span>
          <h3>{article?.headline}</h3>
        </span>
        <div className={'flex-shrink-0'}>
          {article && <BookmarkButton article={article} />}
          <button className={'btn btn-link btn-sm'} onClick={popOut}>
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </button>
          <button className={'btn btn-link link-danger btn-sm'} onClick={onClose}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        {loading && <Loading type={'grid'} />}
        {article && <ArticleDisplay article={article} />}
      </ModalBody>
    </Modal>
  );
}
