import React from 'react';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NewsWidget } from '@/widgets/news-widget';
import SettingsMenu from '@/components/settings/settings-menu';
import { WidgetHost } from '@/widgets/widget-host';
import { RouteObject } from 'react-router-dom';

export class SettingsMenuWidget implements NewsWidget {
  defaultTitle: string = 'Settings';

  readonly id = '/settings';
  initialize(host: WidgetHost) {}

  async hasPermission(permissions: string[]) {
    return true;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faGear} /> Settings
      </>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get mainPanel(): JSX.Element {
    return <SettingsMenu />;
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faGear} />
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: <SettingsMenu />,
      },
    ];
  }

  get defaultRoute(): string {
    return '/';
  }
}
