import { SearchResult } from '@/model/news/search-result';
import { Article, ArticleUtil } from '@/model/article';
import GenreBadge from '@/components/shared/genre-badge';
import KeywordBadge from '@/components/shared/keyword-badge';
import TaggedHeadline from '@/components/shared/tagged-headline';
import AgedDateFormat from '@/components/shared/aged-date-format';
import DateFormat from '@/components/shared/date-format';
import styles from '@/components/articles/article-list.module.scss';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';

interface Props {
  articles?: SearchResult<Article>[];
  onArticleClick: Function;
  colorizeDates?: boolean;
  showGenreTags?: boolean;
  hideAuthor?: boolean;
  showKeywords?: boolean;
}

export default function SearchResultList({ articles, colorizeDates = true }: Props) {
  const navigate = useWidgetNavigate();
  return (
    <div className={'h-100 container-fluid'}>
      {articles?.map((value, index) => {
        let scale = 0.5;
        let hideAuthor = false;

        if (ArticleUtil.hasGenre(value.item, 'HEADLINE')) {
          scale = 5;
          hideAuthor = true;
        }
        if (ArticleUtil.hasGenre(value.item, 'BULLET')) {
          scale = 2;
          hideAuthor = true;
        }

        return (
          <div
            key={'article-' + value.item.uri}
            className={`row mb-2 border-bottom`}
            onClick={event => navigate(value.item.uri)}
          >
            <div className={'col-3 align-top  text-muted '}>
              {colorizeDates ? (
                <AgedDateFormat
                  format={'MMM D h:mma'}
                  date={value.item.versioncreated}
                  scale={scale}
                />
              ) : (
                <DateFormat format={'MMM D h:mma'} date={value.item.versioncreated} />
              )}
              <div className={' small text-muted text-center font-monospace'}>
                {value.score?.toFixed(3) || ''}
              </div>
            </div>

            <div className={`col align-top text-text-dark ${styles.article}`}>
              <span className={'fw-bold'}> {value.item.headline}</span>
              <div
                dangerouslySetInnerHTML={{ __html: value.item.summary }}
                className={`  lh-sm fst-italic fw-lighter ${styles.articleSummary}`}
              ></div>

              {hideAuthor || (
                <div>
                  <span className={`align-top ${styles.articleAuthor}`}>
                    by {value.item.byline}
                  </span>
                </div>
              )}

              {
                <div>
                  {value.item.keywords.map(s => (
                    <span className={'small'}>
                      <KeywordBadge keyword={s} />{' '}
                    </span>
                  ))}
                </div>
              }
            </div>
          </div>
        );
      })}
    </div>
  );
}
